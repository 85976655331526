<template>
    <project-profile-component/>
</template>

<script>
    import ProjectProfileComponent from "@/components/projects/ProjectProfileComponent";
    
    export default {
        name: "ProjectProfile",
        title: "Perfil del Proyecto | Private",
        components: {ProjectProfileComponent}
    }
</script>

<style scoped>

</style>