<template>
    <v-container fluid class="container--fluid fill-height text-left pa-0 backcolor" style="width: 100%">
        <v-row>
            <v-img src="@/assets/images/header-bg.jpg"
                   class="align-end"
                   transition="slide-x-transition"
                   width="100%" height="300">
                <v-row align="center" justify="center">
                    <v-col cols="12">
                        <div class="fill-height container--fluid px-5 px-md-16 pb-10">
                            <v-row align="center">
                                <v-col cols="6">
                                    <span class="font-weight-thin text-h5 mx-2 text-md-h2 white--text">
                                       Perfil de Proyecto
                                    </span>
                                </v-col>
                                <v-col cols="6" class="justify-end text-end">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn rounded color="yellow"
                                                   depressed plain icon
                                                   v-on="on"
                                                   v-bind="attrs"
                                                   v-on:click="goBack">
                                                <v-icon size="48" >mdi-exit-to-app</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Ir atrás</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>
                            <v-divider dark class="mt-2"/>
                        </div>
                    </v-col>
                </v-row>
            </v-img>
        </v-row>
        <v-row align="start" justify="center">
            <v-col cols="12" md="10">
                <v-container fluid class="fill-height mb-10">
                    <v-row align="start" justify="start">
                        <v-col cols="12" md="6">
                            <v-container class="mx-0" fluid>
                                <v-row dense>
                                    <v-col cols="12">
                                    <span class="font-weight-ligh text-h6 text-md-h5 text-uppercase headline d-inline-block text-truncate"
                                          style="max-width: 100%;">
                                      {{ project.name }}
                                    </span>
                                        <v-divider></v-divider>
                                    </v-col>
                                    <v-col cols="12">
                                        <VueSlickCarousel
                                            ref="c1"
                                            :asNavFor="$refs.c2"
                                            :fade="true"
                                            :arrows="false"
                                            :swipe="false"
                                            :focusOnSelect="true"
                                            :slidesToShow="1"
                                            v-if="project.pictures.length>0"
                                            class="mt-3"
                                            style="height: 310px;">
                                            <div v-for="item in project.pictures"
                                                 :key="item.id"
                                                 class="mx-0">
                                                <v-img
                                                    :src="item.url"
                                                    :lazy-src="require('@/assets/images/no-image.jpg')"
                                                    height="300"
                                                    class="rounded-lg white&#45;&#45;text justify-center elevation-1">
                                                </v-img>
                                            </div>
                                        </VueSlickCarousel>
                                        <VueSlickCarousel
                                            ref="c2"
                                            :asNavFor="$refs.c1"
                                            :slidesToShow="$vuetify.breakpoint.mdAndDown ? 2 : 4"
                                            :arrows="true"
                                            :focusOnSelect="true"
                                            v-if="project.pictures.length>0"
                                            style="height: 110px;">
                                            <div v-for="item in project.pictures"
                                                 :key="item.id"
                                                 class="mx-0">
                                                <v-img
                                                    :src="item.url"
                                                    :lazy-src="require('@/assets/images/no-image.jpg')"
                                                    height="100"
                                                    class="rounded-lg white--text justify-center elevation-1 mx-1">
                                                </v-img>
                                            </div>
                                        </VueSlickCarousel>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-card elevation="0" class="ml-0">
                                <v-container class="mx-0" fluid>
                                    <v-row dense>
                                        <v-col cols="12">
                                            <v-row  justify="start">
                                                <v-col>
                                                    <div class="d-inline-block text-truncate align-center align-content-center " style="max-width: 100%;">
                                                        <v-icon color="orange">mdi-map-marker</v-icon>
                                                        <span class="text-body-1 align-center font-weight-medium grey--text text--darken-4"
                                                              style="max-width: 100%;">
                                                            {{ project.location }}
                                                        </span>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-divider></v-divider>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-container fluid class="mt-auto mx-auto px-0 fill-height container--fluid">
                                                <div :id="containerID" :style="containerCSS" class="rounded-lg"></div>
                                            </v-container>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-card elevation="0" class="ml-0">
                                <v-container class="mx-0" fluid>
                                    <v-row dense>
                                        <v-col cols="12">
                                            <span class="text-button font-weight-light">Descripción:</span>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-divider></v-divider>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-row align-content="strech" justify="start">
                                                <v-col cols="1">
                                                    <v-icon color="orange">mdi-format-quote-close</v-icon>
                                                </v-col>
                                                <v-col>
                                                    <span class="text-body-1 font-weight-medium grey--text text--darken-4">
                                                        {{ project.description }}
                                                    </span>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="12">
                                            <span class="text-button font-weight-light">Fecha de Inicio:</span>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-divider></v-divider>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-row align-content="strech" justify="start">
                                                <v-col cols="1">
                                                    <v-icon color="orange">mdi-calendar</v-icon>
                                                </v-col>
                                                <v-col>
                                            <span class="text-body-1 font-weight-medium grey--text text--darken-4">
                                                {{ formatDate(project.start_date) }}
                                            </span>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="12">
                                            <span class="text-button font-weight-light">Fecha Estimada de terminación: </span>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-divider></v-divider>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-row align-content="strech" justify="start">
                                                <v-col cols="1">
                                                    <v-icon color="orange">mdi-calendar</v-icon>
                                                </v-col>
                                                <v-col>
                                            <span class="text-body-1 font-weight-medium grey--text text--darken-4">
                                                {{ formatDate(project.finish_date) }}
                                            </span>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="12" v-if="sessionUser == 'investor'">
                                            <span class="text-button font-weight-light">Inversión: </span>
                                        </v-col>
                                        <v-col cols="12" v-if="sessionUser == 'investor'">
                                            <v-divider></v-divider>
                                        </v-col>
                                        <v-col cols="12" v-if="sessionUser == 'investor'">
                                            <v-row align-content="strech" justify="start">
                                                <v-col cols="1">
                                                    <v-icon color="orange">mdi-webhook</v-icon>
                                                </v-col>
                                                <v-col>
                                            <span class="text-body-1 font-weight-medium grey--text text--darken-4">
                                                {{ project.investment | toCurrency }}
                                            </span>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="12" v-if="sessionUser == 'investor'">
                                            <span class="text-button font-weight-light">Participación del proyecto: </span>
                                        </v-col>
                                        <v-col cols="12" v-if="sessionUser == 'investor'">
                                            <v-divider></v-divider>
                                        </v-col>
                                        <v-col cols="12" v-if="sessionUser == 'investor'">
                                            <v-row align-content="strech" justify="start">
                                                <v-col cols="1">
                                                    <v-icon color="orange">mdi-percent</v-icon>
                                                </v-col>
                                                <v-col>
                                            <span class="text-body-1 font-weight-medium grey--text text--darken-4">
                                                {{ project.project_participation }}%
                                            </span>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="12" v-if="sessionUser == 'investor'">
                                            <span class="text-button font-weight-light">Retorno de Inversión esperado:</span>
                                        </v-col>
                                        <v-col cols="12" v-if="sessionUser == 'investor'">
                                            <v-divider></v-divider>
                                        </v-col>
                                        <v-col cols="12" v-if="sessionUser == 'investor'">
                                            <v-row align-content="strech" justify="start">
                                                <v-col cols="1">
                                                    <v-icon color="orange">mdi-backup-restore</v-icon>
                                                </v-col>
                                                <v-col>
                                            <span class="text-body-1 font-weight-medium grey--text text--darken-4">
                                                {{ project.expected_return_investment | toCurrency }}
                                            </span>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-card elevation="0" class="ml-0">
                                <v-container class="mx-0" fluid>
                                    <v-row dense>
                                        <v-col cols="12">
                                            <v-container fluid class="pa-0 mt-1" v-if="project.youtube_video != null && project.youtube_video != ''">
                                                <iframe
                                                    :src="youtubeVideo"
                                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                                    allowfullscreen
                                                    frameborder="0"
                                                    :height="$vuetify.breakpoint.xs ? 250 : 350"
                                                    width="100%"
                                                    class="rounded-lg">
                                                </iframe>
                                            </v-container>
                                        </v-col>
                                        <v-col cols="12" v-if="sessionUser == 'investor'">
                                            <span class="text-button font-weight-medium">Relación con Inversionistas:</span>
                                        </v-col>
                                        <v-col cols="12" v-if="sessionUser == 'investor'">
                                            <v-divider></v-divider>
                                        </v-col>
                                        <v-col cols="12" v-if="sessionUser == 'investor'">
                                            <v-container fill-height bottom-gradient fluid class="ma-0">
                                                <v-row>
                                                    <v-col>
                                                        <div class="fill-height">
                                                            <v-list dense subheader>
                                                                <v-subheader>Lista de Archivos</v-subheader>
                                                                <v-divider/>
                                                                <v-list-item
                                                                    v-for="document in project.projects_documents"
                                                                    :key="document.id">
                                                                    <v-list-item-icon>
                                                                        <v-icon color="orange">
                                                                            mdi-file-document
                                                                        </v-icon>
                                                                    </v-list-item-icon>
                                                                    <v-list-item-content>
                                                                        <v-list-item-title v-text="document.name"></v-list-item-title>
                                                                    </v-list-item-content>
                                                                    <v-list-item-icon>
                                                                        <v-tooltip bottom v-if="document.url != null">
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                <v-btn
                                                                                    dark
                                                                                    icon
                                                                                    small
                                                                                    color="orange"
                                                                                    class="ma-0 text--darken-1"
                                                                                    elevation="0"
                                                                                    v-bind="attrs"
                                                                                    v-on="on"
                                                                                    v-on:click="openPDF(document)">
                                                                                    <v-icon>mdi-magnify</v-icon>
                                                                                </v-btn>
                                                                            </template>
                                                                            <span>Ver documento</span>
                                                                        </v-tooltip>
                                                                    </v-list-item-icon>
                                                                </v-list-item>
                                                            </v-list>
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-col>
                                        <v-col cols="12" v-if="project.projects_advances.length>0 && sessionUser == 'investor'">
                                            <span class="text-button font-weight-medium">Avances:</span>
                                        </v-col>
                                        <v-col cols="12" v-if="sessionUser == 'investor'">
                                            <v-expansion-panels>
                                                <v-expansion-panel
                                                    v-for="(item, index) in project.projects_advances"
                                                    :key="index">
                                                    <v-expansion-panel-header>
                                                        Fecha de anotación: {{ formatDate(item.date_advance) }}
                                                    </v-expansion-panel-header>
                                                    <v-expansion-panel-content>
                                                        {{ item.comment }}
                                                        <v-divider></v-divider>
                                                        <v-container fluid class="container--fluid mx-auto">
                                                            <v-row>
                                                                <v-col
                                                                    v-for="(image, idx) in item.pictures"
                                                                    :key="idx"
                                                                    class="d-flex child-flex"
                                                                    cols="12" md="3">
                                                                    <v-card
                                                                        flat
                                                                        elevation="0"
                                                                        @click="showPreviewImage(image.url)">
                                                                        <v-img :src="image.url"
                                                                               :lazy-src="require('@/assets/images/no-image.jpg')"
                                                                               contain
                                                                               height="auto"
                                                                               aspect-ratio="1"
                                                                               class="grey lighten-2">
                                                                            <template v-slot:placeholder>
                                                                                <v-row
                                                                                    class="fill-height ma-0"
                                                                                    align="center"
                                                                                    justify="center">
                                                                                    <v-progress-circular
                                                                                        indeterminate
                                                                                        color="grey lighten-5">
                                                                                    </v-progress-circular>
                                                                                </v-row>
                                                                            </template>
                                                                        </v-img>
                                                                    </v-card>
                                                                </v-col>
                                                            </v-row>
                                                        </v-container>
                                                    </v-expansion-panel-content>
                                                </v-expansion-panel>
                                            </v-expansion-panels>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row align="start" justify="center" v-if="sessionUser == 'investor'">
                        <v-col cols="12">
                            <div class="container--fluid fill-height">
                                <VueApexCharts height="350" type="line" :options="chartOptions" :series="series"></VueApexCharts>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
        <v-dialog
            :fullscreen="$vuetify.breakpoint.smAndDown"
            v-model="dialogImage"
            persistent
            hide-overlay
            width="500">
            <v-card>
                <v-toolbar dense dark color="primary">
                    <v-btn
                        class="hidden-sm-and-down"
                        icon
                        dark
                        @click="dialogImage = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>Previsualizar la imagen</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn dark
                               text
                               @click="dialogImage = false">
                            Cerrar
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-container fill-height bottom-gradient fluid class="ma-0">
                    <v-row>
                        <v-col cols="12">
                            <v-img :src="previewImage"
                                   :lazy-src="require('@/assets/images/no-image.jpg')"
                                   height="500"
                                   width="500"
                                   aspect-ratio="1"
                                   class="grey lighten-2">
                                <template v-slot:placeholder>
                                    <v-row
                                        class="fill-height ma-0"
                                        align="center"
                                        justify="center">
                                        <v-progress-circular
                                            indeterminate
                                            color="grey lighten-5">
                                        </v-progress-circular>
                                    </v-row>
                                </template>
                            </v-img>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
    import mapboxgl from "mapbox-gl";
    import "mapbox-gl/dist/mapbox-gl.css";
    import VueSlickCarousel from 'vue-slick-carousel';
    import "vue-slick-carousel/dist/vue-slick-carousel.css";
    // import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
    import VueApexCharts from "vue-apexcharts";
    import projectsService from '@/providers/ProjectsService';
    // import axios from "axios";
    
    export default {
        props: {
            initialLocation: {
                type: Array,
                default: () => [-116.97480855890304, 32.547976944620714],
            },
            height: {
                type: String,
                default: "400px",
            },
            mapStyle: {
                type: String,
                default: "mapbox://styles/mapbox/streets-v11",
            },
            color: {
                type: String,
                default: "orange",
            },
            containerID: {
                type: String,
                default: "map",
            }
        },
        name: "ProjectProfileComponent",
        components: { VueSlickCarousel, VueApexCharts },
        data: () => ({
            model: null,
            dialogImage: false,
            previewImage: undefined,
            youtubeVideo: '',
            mapBoxApiKey: process.env.VUE_APP_MAPBOX_API_KEY,
            location: [],
            projects: [],
            sessionInfo: null,
            sessionUser: null,
            investor: null,
            projects_advances: [],
            project: {
                id: 0,
                name: null,
                description: null,
                location: null,
                map_lat: 0.0,
                map_lng: 0.0,
                start_date: new Date(Date.now()).toISOString(),
                finish_date: new Date(Date.now()).toISOString(),
                investment_amount: 0,
                project_participation: 0,
                expected_return_investment: 0,
                youtube_video: null,
                active: true,
                status: 'waiting',
                pictures: [],
                investors: [],
                projects_advances: [],
                projects_documents: []
            },
            chartOptions: {
                chart: {
                    id: 'chart-project',
                    height: 350,
                    type: 'line',
                    zoom: {
                        enabled: false,
                    },
                    toolbar: {
                        show: false,
                    },
                },
                tooltip: {
                    enabled: true,
                    x: {
                        show: true,
                        format: 'dd MMM yyyy'
                    },
                    custom: function({series, seriesIndex, dataPointIndex, w}) {
                        console.debug('series:', series);
                        let data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
                        let theDate = data.x.toString().substr(0,10);
                        const [year, month, day] = theDate.split('-');
                        let fecha = `${day}-${month}-${year}`;
                        return '<v-card elevation="2" class="ma-3">' +
                                '<table cellpadding="3" cellspacing="3">' +
                                    '<tr class="card-header">' +
                                        '<th>Fecha: </th>' +
                                        '<td>' +
                                            '<v-card-title class="grey--text text--darken-3">'+fecha+'</v-card-title>' +
                                        '</td>' +
                                    '</tr>' +
                                    '<tr>' +
                                        '<th>Avance:</th>' +
                                        '<td>' + data.y.toFixed(0) + '%' + '</td>' +
                                    '</tr>' +
                                    '<tr>' +
                                        '<th>Descripción:</th>' +
                                        '<td>' + data.description + '</td>' +
                                    '</tr>' +
                                '</table>' +
                            '</v-card>';
                    }
                    /*y: {
                        formatter: function(value, { series, seriesIndex, dataPointIndex }) {
                           // const sum = opts.series[0].reduce((a, b) => a + b, 0);
                           // const percent = (value / sum) * 100;
                            console.log('series:', series);
                            console.log('seriesIndex:', seriesIndex);
                            console.log('dataPointIndex:', dataPointIndex);
                            // console.log('description_array:', this.description_array[dataPointIndex]);
                            return value.toFixed(0) + '%';
                        },
                    },*/
                },
                dataLabels: {
                    enabled: true,
                    enabledOnSeries: [0],
                    formatter: function(val) {
                        return val.toFixed(0) +  '%';
                    },
                },
                // colors: ['#334adf', '#c40628'],
                colors: ['#334adf', 'transparent'],
                stroke: {
                    curve: 'smooth',
                    // curve: 'straight'
                },
                title: {
                    text: 'Comportamiento de Avances del Proyecto',
                    align: 'left'
                },
                grid: {
                    row: {
                        colors: ['#f3f3f3', 'transparent'],
                        opacity: 0.5,
                    },
                },
                markers: {
                    size: 1
                },
                legend: {
                    show: true,
                    showForZeroSeries: false,
                    position: 'top',
                    horizontalAlign: 'right',
                    floating: true,
                    offsetY: -25,
                    offsetX: -5
                },
                xaxis: {
                    title: {
                        text: 'Línea de tiempo'
                    },
                    type: "datetime",
                    format: 'MM/yyyy',
                    labels: {
                        style: {
                            cssClass: "grey--text lighten-2--text fill-color",
                        },
                    }
                },
                yaxis: {
                    title: {
                        text: 'Línea de tiempo'
                    },
                    type: "numeric",
                    labels: {
                        style: {
                            cssClass: "grey--text lighten-2--text fill-color",
                        },
                    }
                }
            },
            series: [
                {
                    name: "Avances",
                    data: []
                }
            ],
        }),
        computed: {
            containerCSS() {
                return {
                    width: "100%",
                    height: this.height,
                };
            }
        },
        filters: {
            toCurrency (value) {
                if (typeof value !== "number") {
                    return value;
                }
                var formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD'
                });
                return formatter.format(value);
            }
        },
        methods: {
            formatDate(value) {
                if (!value) return null;
                let theDate = value.toString().substr(0,10);
                const [year, month, day] = theDate.split('-')
                return `${day}-${month}-${year}`;
            },
            formatText(text){
                let arr = [];
                // text.split('\n').forEach(item=>arr.push(`<p>${item.trim()}</p>`));
                text.split('\n').forEach(item=>arr.push(`<span>${item.trim()}</span>`));
                return arr.join('<br>');
            },
            arrayTextLine(val) {
                let lines = val.replace(/\r\n/g,"\n").split("\n");
                let textArray = [];
                for (var i=0; i < lines.length; i++) {
                    if (/\S/.test(lines[i])) {
                        textArray.push(lines[i].trim(lines[i]));
                    }
                }
                // console.log('Array de elementos texts: ', textArray);
                return textArray;
            },
            goBack() {
                let view = this.$route.params.view;
                if (view === 'project') {
                    this.$router.push({path: '/projects'});
                } else {
                    this.$router.push({path: '/investor'});
                }
            },
            toTop() {
                this.$vuetify.goTo(0);
            },
            loadURL () {
                const youtubeEmbedTemplate = 'https://www.youtube.com/embed/';
                const url = this.project.youtube_video.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/);
                // console.log("url", url);
                const YId = undefined !== url[2] ? url[2].split(/[^0-9a-z_/\\-]/i)[0] : url[0];
                // console.log("YId", YId);
                if (YId === url[0]) {
                    console.log("not a youtube link");
                } else {
                    console.log("it's  a youtube video");
                }
                const topOfQueue = youtubeEmbedTemplate.concat(YId);
                // console.log("topOfQueue", topOfQueue);
                this.youtubeVideo = topOfQueue;
            },
            openPDF(document) {
                //  let baseURL = this.$store.getters.getBaseURL;
                let urlPdf = document.url; // `${baseURL}/view-pdf`;
                window.open(urlPdf, "_blank");
            },
            showPreviewImage(imageUrl) {
                // console.log(imageUrl);
                this.previewImage = imageUrl;
                this.dialogImage = true;
            },
            getProjects() {
                // Lista de Proyectos
                const archived = Number(false);
                projectsService.getViewList(archived).then(record => {
                    this.projects = record.value;
                    this.loadingData = false;
                });
            },
            getProject(project_id) {
                let record = null;
                projectsService.getRecord(project_id).then(fetch_data => {
                    record = fetch_data.value;
                    // console.log('record:', record);
                    this.project.id = record.id;
                    this.project.name = record.name;
                    this.project.description = record.description;
                    this.project.location = record.location;
                    this.project.map_lat = record.map_lat;
                    this.project.map_lng = record.map_lng;
                    this.project.start_date = record.start_date;
                    this.project.finish_date = record.finish_date;
                    this.project.investment = record.investment;
                    // this.project.investment_amount = record.investment_amount;
                    this.project.project_participation = record.project_participation;
                    // this.project.utilities = record.utilities;
                    // this.project.return_percentage = record.return_percentage;
                    this.project.expected_return_investment = record.expected_return_investment;
                    this.project.youtube_video = record.youtube_video;
                    this.project.active = record.active;
                    this.project.status = record.status;
                    this.project.pictures = record.pictures;
                    this.project.investors = record.investors;
                    this.project.projects_advances = record.projects_advances;
                    this.project.projects_documents = record.projects_documents;
    
                    // Gestion de la ubicacion en el mapa.
                    const lng = this.project.map_lng;
                    const lat = this.project.map_lat;
                    this.setLocationCoordinates({lng, lat});
                    this.createMap();
    
                    if (this.project.youtube_video != null) {
                        this.loadURL();
                    }
                    
                    this.getAdvanceProject(project_id);
                    
                    this.toTop();
                });
            },
            getAdvanceProject(project_id) {
                projectsService.getViewListAdvance(project_id).then(records => {
                    // console.log('records', records.value);
                    let project_data = records.value;
                    let dataObjects = records.value.advances;
                    this.projects_advances = [];
                    let dataSerie = [];
                    let datesValue = [];
                    this.series = [];
                    let values = [];
                    dataObjects.forEach(object => {
                        this.projects_advances.push(object);
                    });
                    this.projects_advances.forEach(data => {
                        datesValue.push(data.advance_date);
                        dataSerie.push(data.percent);
                        let dataValue = {
                            x : data.advance_date,
                            y : data.percent,
                            description: data.advance_description
                        };
                        values.push(dataValue);
                    });
                    this.series = [
                        {
                            name: "Avances",
                            type: "line",
                            data: values
                        },
                        {
                            name: "Proyecto",
                            type: "line",
                            data: [
                                {
                                    x: project_data.start_date,
                                    y: 0 // project_data.initial_percent
                                },
                                {
                                    x: project_data.finish_date,
                                    y: 0 // project_data.final_percent
                                }
                            ]
                        }
                    ];
                });
            },
            updateSeriesLine() {
                this.$refs.realtimeChart.updateSeries([{
                    data: this.series[0].data,
                }], false, true);
            },
            async createMap() {
                try {
                    mapboxgl.accessToken = this.mapBoxApiKey;
                    // Create map object
                    this.map = new mapboxgl.Map({
                        container: this.containerID,
                        style: this.mapStyle,
                        center: this.location,
                        zoom: 10,
                    });
                    // Controles basicos de navegacion: zoom y rotacion.
                    this.map.addControl(new mapboxgl.NavigationControl());
            
                    // Evento del mapa.
                    this.map.on('load', () => {
                        // console.log('here');
                        this.marker = new mapboxgl.Marker().setLngLat(this.location).addTo(this.map);
                        // console.log(this.marker);
                    });
                } catch (err) {
                    console.log("map error", err);
                }
            },
            setLocationCoordinates(lngLat) {
                this.location = [lngLat.lng, lngLat.lat];
            }
        },
        mounted() {
            this.sessionUser = this.$route.params.user;
            this.investor = Object.assign({}, JSON.parse(sessionStorage.getItem('investor')));
            // console.log('Inversionista Storage 1: ', this.investor);
            if (this.investor.user == null) {
                console.log('Usuario invitado...');
                // this.sessionInfo = 'guest';
            } else {
                console.log('Usuario inversionista...');
                // this.sessionInfo = 'investor';
            }
            this.projects = [];
            this.getProject(this.$route.params.id);
        }
    }
</script>

<style scoped>
    .backcolor {
        background-color: #ffffff;
        margin-top: 0px;
    }
    .v-btn {
        text-transform: none !important;
    }
    .my-slider > .v-slide-group__next.theme--light.v-icon {
        color: rgb(234, 10, 10) !important;
        font-size: 24pt !important;
    }
    .card {
        min-height: 150px;
        padding: 5px;
        transition: 0.5s ease-out;
    }
    .card .v-image {
        margin-bottom: 5px;
        transition: 0.75s;
    }
    .card h1 {
        margin-bottom: 10px;
    }
    .show-white-space {
        white-space: pre-line;
        /*white-space: pre-wrap;*/
    }

    /*This is for custom box*/
    .apexcharts-tooltip {
        border-radius: 12px;
        box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
    }
    /* This is for custom header*/
    &.apexcharts-theme-light .apexcharts-tooltip-title {
           font-family: Lato !important;
           background-color: white;
           border-bottom: 0;
           font-weight: bold;
    }
    /*This is for custom headers*/
    .apexcharts-tooltip-text-value {
       font-weight: normal;
       font-family: Lato;
       font-size: 12px;
   }
</style>